<template>
	<div class="project-box">
		<div class="heard">
			<p class="title">项目总览</p>
			<div class="right">
				<el-select class="margin-r curator-select" v-model="searchCurator" clearable filterable placeholder="请选择负责人">
					<el-option
						v-for="(i, index) in curatorOptions.filter(el => {
							if (searchCompany) {
								return el.company == searchCompany;
							} else {
								return true;
							}
						})"
						:key="index"
						:label="i.name"
						:value="i.value"
					></el-option>
				</el-select>
				<el-radio-group class="margin-r" v-model="creationTime">
					<el-radio-button label="全部">全部</el-radio-button>
					<el-radio-button label="本月">本月</el-radio-button>
					<el-radio-button label="本季">本季</el-radio-button>
					<el-radio-button label="本年">本年</el-radio-button>
				</el-radio-group>
				<el-select v-if="judgment('authorityList', 207, this)" class="margin-r companyType" v-model="searchCompanyType" clearable filterable placeholder="请选择公司类型">
					<el-option v-for="(i, index) in companyTypeOptions" :key="index" :label="i.label" :value="i.value"></el-option>
				</el-select>
				<el-select class="margin-r" v-model="searchCompany" clearable filterable placeholder="请选择公司" @change="companyChange">
					<el-option v-for="(i, index) in companyOptions" :key="index" :label="i.company" :value="i.company"></el-option>
				</el-select>
				<el-cascader
					class="margin-r cascader"
					v-model="address"
					:options="addressList"
					:props="{ value: 'name', label: 'name', checkStrictly: true }"
					placeholder="请选择地域"
					clearable
				></el-cascader>
				<!-- <el-select class="margin-r" v-model="searchProject" clearable filterable placeholder="请选择项目" @change="projectChange">
					<el-option
						v-for="(i, index) in projectList.filter(item => {
							if (searchCompany) {
								return searchCompany == item.company;
							} else {
								return item;
							}
						})"
						:key="index"
						:label="i.project"
						:value="i.project"
					></el-option>
				</el-select> -->
				<el-select class="margin-r status-select" v-model="state" clearable placeholder="请选择状态">
					<el-option v-for="item in stateOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
				</el-select>
			</div>
		</div>
		<div class="list-box" id="projectList">
			<ul class="heard-ul">
				<li class="width-s li-style">编号</li>
				<li class="project-li li-style">项目名称</li>
				<li class="person-in-charge li-style">负责人</li>
				<li class="enterprise-li li-style">企业名称</li>
				<li class="address-li li-style">地域</li>
				<li class="status">
					<p class="line-s">项目状态</p>
					<p class="line-s border-top second">在测&nbsp;/&nbsp;结束</p>
				</li>
				<li class="time li-style">创建时间</li>
				<li class="time li-style">完成时间</li>

				<li class="operation li-style operationWidth">检测参数</li>
			</ul>
			<div class="list-scroll">
				<vue-scroll :ops="ops" v-show="dataList.length != 0">
					<div id="ul-content">
						<ul :class="i.state == 1 ? 'content-ul' : 'content-ul background-gray'" v-for="(i, index) in dataList" :key="index">
							<li class="width-s line-style">{{ index + 1 }}</li>
							<li class="project-li line-style txt-left text-overflow pointer" :title="i.name" @click="details(i, 'vibration')">{{ i.name }}</li>
							<li class="person-in-charge txt-left line-style text-overflow" :title="i.person">{{ i.person }}</li>
							<li class="enterprise-li line-style txt-left text-overflow" :title="i.cname">{{ i.cname }}</li>
							<li class="address-li line-style txt-left text-overflow" :title="i.address">{{ i.address }}</li>
							<li :class="i.state == 1 ? 'status line-style green-color' : 'status line-style'">{{ i.state == 1 ? '监测中' : '已结束' }}</li>
							<li class="time line-style" :title="i.cdate">{{ i.cdate }}</li>
							<li class="time line-style" :title="i.edate">{{ i.edate }}</li>
							<li class="operation but-li">
								<el-button v-if="'zd' in i" type="primary" plain size="mini" @click="details(i, 'vibration')">振动【{{ i.zd }}】</el-button>
								<!-- <el-button type="primary" size="mini" @click="details(i, 'deformation')">裂缝【8888】</el-button>
								<el-button type="primary" size="mini">应变【8888】</el-button>
								<el-button type="primary" size="mini">收敛【8888】</el-button>
								<el-button type="primary" size="mini">位移【8888】</el-button> -->
							</li>
						</ul>
					</div>
					<ul class="bottom-ul" id="statistical_ul" v-for="(i, index) in statistical" :key="index">
						<li class="width-s line-style">统计</li>
						<li class="project-li line-style" :title="i.nameNum">{{ i.nameNum }}</li>
						<li class="person-in-charge li-style"></li>
						<li class="enterprise-li line-style" :title="i.cnameNum">{{ i.cnameNum }}</li>
						<li class="address-li li-style"></li>
						<li class="status line-style" :title="i.test + '/' + i.end">{{ i.test }}/{{ i.end }}</li>
						<li class="time li-style"></li>
						<li class="time li-style"></li>
						<li class="operation operationWidth"></li>
					</ul>
				</vue-scroll>
				<noData v-show="dataList.length == 0" />
			</div>
		</div>
	</div>
</template>

<script>
import areaData from '../../../assets/common/Area.json';
export default {
	name: '',
	data() {
		return {
			searchCompany: '', //选择的公司
			companyOptions: [],
			searchCompanyType: '', // 选择的公司类型
			companyTypeOptions: [
				{
					label: '自营',
					value: 1
				},
				{
					label: '合作',
					value: 2
				},
				{
					label: '协助',
					value: 3
				}
			],
			searchProject: '', //选择的项目查询内容
			projectList: [], //项目下拉查询列表
			address: '', // 选择的地域数据
			addressList: [], // 地域可选择列表
			creationTime: '全部', // 创建时间选择
			searchCurator: '', // 选择的负责人
			curatorOptions: [], //可选择的负责人
			state: null, //选择的状态
			stateOptions: [
				{
					value: 2,
					name: '全部'
				},
				{
					value: 1,
					name: '监测中'
				},
				{
					value: 0,
					name: '已结束'
				}
			], //状态选择数据列表
			list: [], //列表数据
			statistical: [], // 统计数据
			seachName: '', //搜索的内容
			authorityList: [], //权限数组
			ops: {
				bar: {
					onlyShowBarOnScroll: false, //是否只在滚动时显示
					keepShow: true // 是否一直显示
				}
			} //滚动条配置
		};
	},
	created() {
		this.getPermissions();
	},
	mounted() {
		this.getList();
		this.bus.$on('project', i => {
			this.seachName = i;
		});
		this.addressList = this.regionalHandle(areaData.data, 0);
		// // 页面尺寸变化监听
		// window.addEventListener('resize', () => {
		// 	this.unfolding(true);
		// });
	},
	computed: {
		dataList: function() {
			$('.operation').removeAttr('style');
			var filterCompany = this.list.filter(item => {
				if (this.searchCompany != '') {
					return item.cname == this.searchCompany;
				} else {
					return item;
				}
			});
			var filterCompanyType = filterCompany.filter(item => {
				if (this.searchCompanyType != '') {
					return item.ctype == this.searchCompanyType;
				} else {
					return item;
				}
			});
			var filterAddress = filterCompanyType.filter(item => {
				if (this.address != '') {
					return item.address.indexOf(this.address) != -1;
				} else {
					return item;
				}
			});
			let timeQuery = [];
			let time = new Date().getTime();
			if (this.creationTime == '本月') {
				timeQuery.push(new Date().format('yyyy-MM'));
			} else if (this.creationTime == '本季') {
				let quarter = [['01', '02', '03'], ['04', '05', '06'], ['07', '08', '09'], ['10', '11', '12']];
				let month = new Date().format('MM');
				try {
					quarter.forEach(q => {
						if (q.includes(month)) {
							q.forEach(m => {
								timeQuery.push(new Date().format('yyyy-') + m);
							});

							throw '';
						}
					});
				} catch (e) {
					//TODO handle the exception
				}
			} else if (this.creationTime == '本年') {
				for (let i = 1; i < 13; i++) {
					timeQuery.push(new Date().format('yyyy-') + (i < 10 ? '0' + i : i));
				}
			}
			var filterTime = filterAddress.filter(item => {
				if (this.creationTime != '全部') {
					let timeText = item.cdate.substring(0, 7);
					return timeQuery.includes(timeText);
				} else {
					return item;
				}
			});
			var filterCurator = filterTime.filter(item => {
				if (this.searchCurator != '') {
					return item.person.indexOf(this.searchCurator) != -1;
				} else {
					return item;
				}
			});

			var arr = filterCurator.filter(item => {
				if (this.searchProject != '') {
					return item.name == this.searchProject;
				} else {
					return item;
				}
			});
			let arrData = arr.filter(i => {
				if (this.state === null || this.state === '') {
					return i;
				} else if (this.state == 2) {
					return i;
				} else {
					if (i.state == this.state) {
						return i;
					}
				}
			});
			this.$nextTick(() => {
				let widthArr = [];
				$.each($('.but-li'), (index, value) => {
					widthArr.push($(value).outerWidth(true));
				});
				if (widthArr.length) {
					$('.operation').css({
						width: Math.max.apply(null, widthArr) + 'px',
						flex: 'none'
					});
				}
				// 统计一行位置调整
				this.calculateLocation();
			});
			this.statisticalReset(arrData);
			return arrData;
		}
	},
	methods: {
		// 页面权限请求
		getPermissions() {
			this.authorityList = [];
			this.axios.get('/web2/home/odnr/gthy').then(res => {
				if (res.status) {
					this.authorityList = res.data;
				}
			});
		},
		// 获取项目列表
		getList() {
			this.axios.post('/web2/home/odnr/gpjlist2').then(res => {
				this.list = [];
				this.statistical = [];
				if (res.status) {
					this.list = res.data;
					res.data.forEach(item => {
						if (item.person) {
							if (item.person.indexOf('；') != -1) {
								let person = item.person.split('；');
								person.forEach(el => {
									let ind = this.curatorOptions.findIndex(v => {
										return v.name == el;
									});
									if (ind == -1) {
										this.curatorOptions.push({
											value: el,
											name: el,
											company: item.cname
										});
									}
								});
							} else {
								let ind = this.curatorOptions.findIndex(v => {
									return v.name == item.person;
								});
								if (ind == -1) {
									this.curatorOptions.push({
										value: item.person,
										name: item.person,
										company: item.cname
									});
								}
							}
						}
						if (item.name != '') {
							let ind = this.projectList.findIndex(el => {
								return item.name == el.project;
							});
							if (ind == -1) {
								this.projectList.push({
									company: item.cname,
									project: item.name
								});
							}
						}
						if (item.company != '') {
							let inde = this.companyOptions.findIndex(el => {
								return item.cname == el.company;
							});
							if (inde == -1) {
								this.companyOptions.push({
									company: item.cname,
									project: item.name
								});
							}
						}
					});
				}
			});
		},
		// 公司选择
		companyChange() {
			this.searchProject = '';
			this.searchCurator = '';
		},
		// 项目选择
		projectChange() {
			this.searchCompany = '';
		},
		// 地域级联选择器数据处理
		regionalHandle(data, hierarchy) {
			let arr = [];
			data.forEach((el, ind) => {
				let obj = {
					code: el.code,
					name: el.name
				};
				if (hierarchy < 1 && 'children' in el) {
					obj.children = this.regionalHandle(el.children, hierarchy + 1);
				}
				arr.push(obj);
			});
			return arr;
		},
		// 列表筛选生效后重置统计数据
		statisticalReset(data) {
			let statistical = {
				nameNum: 0, //项目总数
				cnameNum: 0, //企业总数
				test: 0, // 在测项目总数
				end: 0 // 结束项目总数
			}; // 统计数据
			this.statistical = [];
			statistical.nameNum = data.length;
			let cnameArr = [];
			data.forEach(i => {
				if (!cnameArr.includes(i.cname)) {
					cnameArr.push(i.cname);
				}
				if (i.state == 1) {
					statistical.test += 1;
				} else {
					statistical.end += 1;
				}
			});
			statistical.cnameNum = cnameArr.length;
			this.statistical.push(statistical);
		},
		// 查看详情
		details(val, routerType = 'vibration') {
			var data = {
				name: val.name,
				id: val.id,
				cId: val.cid,
				img: val.pn1,
				routerType: routerType
			};
			if (routerType == 'vibration') {
				this.toRouter('point', data, 'query');
			} else if (routerType == 'deformation') {
				let deformationPathText = localStorage.getItem('deformationPath');
				let deformationPath = deformationPathText.split(',');
				if (deformationPath.length != 0) {
					this.toRouter(deformationPath[0], data, 'query');
				}
			}
		},
		// 计算统计一行位置
		calculateLocation() {
			$(() => {
				var height = $('.list-scroll').height();
				var len = this.dataList.length;
				let contentUlH = $('.content-ul').outerHeight(true); //列表内容每行高度
				let statisticalH = $('#statistical_ul').outerHeight(true); //统计行的高度加边框
				if (len * contentUlH + statisticalH >= height) {
					$('#ul-content').css({
						paddingBottom: statisticalH + 'px'
					});
					$('.bottom-ul').css({
						position: 'fixed',
						left: '0',
						top: height - statisticalH + 'px'
					});
				} else {
					$('#ul-content').css({
						paddingBottom: '0'
					});
					$('.bottom-ul').removeAttr('style');
				}
			});
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.project-box {
	width: 100%;
	height: 100%;
	position: relative;

	.heard {
		margin: 10px 0 10px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.title {
			font-size: 17px;
			font-weight: 600;
			color: #303133;
			letter-spacing: 1px;
			margin-right: 45px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			// text-shadow: 3px 3.5px 2px #bfc3cb;
		}

		.right {
			display: flex;
			align-items: center;
			margin-right: 15px;

			::v-deep {
				.el-input__inner {
					height: 35px;
				}

				.el-input__icon {
					line-height: 35px;
				}

				.companyType {
					.el-input {
						width: 155px;
					}
				}

				.cascader {
					.el-input {
						width: 190px;
					}
				}

				.curator-select {
					.el-input {
						width: 135px;
					}
				}

				.status-select {
					.el-input {
						width: 135px;
					}
				}

				.el-radio-group {
					.el-radio-button__inner {
						padding: 9px 10px;
						line-height: inherit;
					}
				}
			}

			.margin-r {
				margin-right: 20px;
			}
		}
	}
	// 列表
	.list-box {
		width: calc(100% - 20px);
		height: calc(100% - 60px);
		padding: 0 10px;

		ul {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			border-top: thin solid #dcdfe6;

			li {
				// padding-left: 8px;
				height: 100%;
				border-right: thin solid #dcdfe6;
				text-align: center;

				.flex {
					display: flex;
					justify-content: space-around;
					align-items: center;

					.type-p-s {
						width: 33%;
					}

					.type-p {
						width: 50%;
					}

					.type-p-w {
						width: 100%;
					}

					.type-p:first-child {
						border-right: thin solid #dcdfe6;
					}

					.type-p-s {
						border-right: thin solid #dcdfe6;
					}

					.type-p-s:last-child {
						border-right: none;
					}
				}

				.border-top {
					border-top: thin solid #dcdfe6;
				}

				.green {
					color: #2fba4f;
				}

				.grey {
					color: gray;
				}

				.yellow {
					color: #ffb000;
					font-size: 16px;
					font-weight: 600;
				}

				.red {
					color: #d90000;
					font-size: 16px;
					font-weight: 600;
				}
			}

			li:first-child {
				border-left: thin solid #dcdfe6;
			}

			.txt-left {
				text-align: left;
				text-indent: 6px;
			}

			.width-s {
				width: 4%;
				min-width: 60px;
			}

			.address-li {
				width: 9%;
				min-width: 140px;
			}

			.status {
				width: 7%;
				min-width: 100px;
			}

			.time {
				width: 6%;
				min-width: 100px;
			}

			.person-in-charge {
				width: 6.5%;
				min-width: 110px;
			}

			.project-li {
				width: 25%;
			}

			.enterprise-li {
				width: 17%;
			}

			.operation {
				flex: 1;
				padding: 0 10px;
			}

			.padding-style {
				padding: 3px 0;
			}

			.margin-style {
				margin: 3px 0;
			}
		}

		.heard-ul {
			height: 58px;
			// background-color: #87cbcb;
			background-color: #ebeef5;

			li {
				font-size: 15px;
				font-weight: 600;
				color: #4c4e51;
				letter-spacing: 1px;

				.type-p,
				.type-p-s,
				.type-p-w {
					height: 30px;
					line-height: 30px;
				}

				.second {
					font-size: 15px;
					font-weight: 500 !important;
					// color: #67696d;
				}
			}

			.li-style {
				line-height: 58px;
			}

			.line-s {
				line-height: 27px;
			}
		}

		.list-scroll {
			width: 100%;
			height: calc(100% - 65px);
			max-height: calc(100% - 65px);
			position: relative;
			transform: scale(1); //此处是为了让统计那一行相对于这个元素定位

			::v-deep {
				.__view {
					width: 100% !important;

					.content-ul:last-child {
						border-bottom: thin solid #dcdfe6;
					}
				}
			}

			.content-ul:nth-child(odd) {
				background-color: #ffffff;
			}

			.content-ul:nth-child(even) {
				background-color: #f2f2f2;
			}

			.content-ul:hover {
				background-color: #ebeef5;
			}

			// 表格内容
			.content-ul {
				height: 39px;

				li {
					font-size: 14px;
					color: #606266;
				}

				.green-color {
					color: #67c23a;
				}

				.but-li {
					display: flex;
					justify-content: flex-start;
					align-items: center;

					::v-deep {
						.el-button {
							padding: 4px 0 4px 5px;
							font-size: 14px;
						}
					}
				}

				.line-style {
					line-height: 39px;
				}
			}

			.background-gray {
				li {
					color: #c0c4cc !important;

					p {
						color: #c0c4cc !important;
					}
				}
			}
		}
		// 表格底部统计
		.bottom-ul {
			border-bottom: thin solid #dcdfe6;
			background-color: #ebeef5;

			li {
				height: 35px;
				padding-top: 17px;
				font-size: 17px;
				font-weight: 600;
				color: #4c4e51;
				text-align: center;
				letter-spacing: 1px;
				word-wrap: break-word;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
		}
	}
}
</style>
