<template>
	<div class="page-box" v-if="project"><project ref="project"></project></div>
</template>

<script>
// 项目
import project from './child/project.vue';
export default {
	name: '',
	data() {
		return {
			project: false,//页面是否加载
		};
	},
	created() {},
	mounted() {},
	computed: {},
	methods: {},
	components: {
		project
	},
	beforeRouteEnter(to, from, next) {
		// // console.log(to);
		// // console.log(from);
		// 注意，在路由进入之前，组件实例还未渲染，所以无法获取this实例，只能通过vm来访问组件实例
		next(vm => {
			if (from.name == 'login') {
				location.reload();
				setTimeout(()=>{
					vm.project = true;
				},300)
			}else{
				vm.project = true;
			}
			
		});
	},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.page-box {
	width: 100%;
	height: 100%;
}
</style>
